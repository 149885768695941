<template>
    <div class='button-bar'>
        <slot></slot>
    </div>
</template>

<style scoped>
    .button-bar {
        /* background: red; */
        display: grid;
        grid-template-columns: 80px 1fr 80px;
        margin-top: 20px;
    }
</style>