<template>
    <div class='artist-gallery'>
        <transition name='artist-card'>
            <artist-card v-if='showCard' :artist='artist'></artist-card>
        </transition>
        <button class='close-button' @click='closeGallery'>✕</button>
    </div>
</template>

<script>
import ArtistCard from './ArtistCard'

export default {
    props: ['artists', 'active'],
    emits: ['close-gallery'],
    data() {
        return {
            showCard: true
        }
    },
    components: {
        ArtistCard
    },
    computed: {
        artist() {
            return this.artists.find(artist => artist.name === this.active);
        }
    },
    methods: {
        closeGallery() {
            this.$emit('close-gallery');
        }
    },
    watch: {
        active(newVal) {
            if (newVal !== '') {
                this.showCard = false;
                setTimeout(() => {this.showCard = true}, 200);
            }
        }
    }
}
</script>

<style scoped>
    .artist-gallery {
        height: calc(100% - 30px);
        /* backdrop-filter: var(--standard-backdrop); */
        grid-row: 3 / 4;
        box-sizing: border-box;
        margin-top: 30px;
        border-radius: 40px;
        box-shadow:
            inset 0 0 5px var(--purplish);
        /* transition: all 0.5s; */
        overflow: hidden;
        background-color: var(--purplish-transparent);
        transform: scale(1);
        border: 1px dotted var(--greenish);
    }

    .artist-card-enter-from {
        opacity: 0;
        transform: translateX(100px);
    }
    .artist-card-enter-to {
        opacity: 1;
        transform: translateX(0);
    }

    .artist-card-leave-from {
        opacity: 1;
        transform: translateX(0);
    }
    .artist-card-enter-active {
        transition: all 0.2s ease-out;
    }
    
    .artist-card-leave-to {
        opacity: 0;
        transform: translateX(-100px);
    }
    .artist-card-leave-active {
        transition: all 0.2s ease-in;
    }

    .close-button {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 1.5rem;
        border: none;
        background-color: transparent;
        color: var(--pinkish);
        text-shadow: 0 0 5px var(--pinkish);
        outline: none;
        transition: all 0.2s ease;
    }

    .close-button:hover {
        text-shadow: 0 0 20px var(--pinkish),
        0 0 20px var(--pinkish),
        0 0 20px var(--pinkish);
    }
</style>