<template>
    <div class='base-section'>
        <slot></slot>
    </div>
</template>

<style scoped>
    .base-section {
        min-height: 100px;
        height: 100vh;
        border-radius: 40px;
        /* box-shadow: 0 0 10px var(--pinkish); */
        /* border: 1px solid var(--pinkish); */
        /* transform: translateX(50px) scale(1); */
        padding: 20px 10px;
        box-sizing: border-box;
        max-width: 800px;
        margin: 0 auto;
 }
</style>