<template>
    <div class='concept-container landing-container'>
        <base-title :text='siteLanguage === "de" ? "konzept" : "concept"'></base-title>
        <concept-selector :concepts='concepts' :active='active' @select-concept='selectConcept'></concept-selector>
        <transition name='concept-gallery'>
            <concept-gallery v-if='active !== ""' :concepts='concepts' :active='active' @close-gallery='selectConcept("")'></concept-gallery>
        </transition>
    </div>
</template>

<script>
import { watch, ref, computed } from 'vue'
import { useStore } from 'vuex'
import ConceptSelector from '../UI/ConceptSelector'
import ConceptGallery from './ConceptGallery'
export default {
    components: {
        ConceptSelector,
        ConceptGallery
    },
    setup() {
        const store = useStore();
        const concepts = computed(() => store.getters['landing/concepts']);

        const active = ref('');

        watch(active, (newValue) => {
            if(newValue !== '') {
                setTimeout(() => {
                    document.querySelector('.concept-container').style.gridTemplateRows = '4rem 10fr 30fr';
                }, 50);
            } else {
                setTimeout(() => {
                    document.querySelector('.concept-container').style.gridTemplateRows = '4rem 1fr 0fr';
                }, 600);
            }
        });

        function selectConcept(concept) {
            active.value = concept
        }
        
        return {
            concepts,
            active,
            selectConcept
        }
    },
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>
    @media only screen and (min-width: 1200px) {
        .concept-container {
            width: 300px;
        }
    }
    .concept-container {
        height: 100%;
        /* padding: 20px; */
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;

        grid-template-rows: 4rem 1fr 0fr;
        box-sizing: border-box;
        transition: all 0.5s;
    }

    .concept-gallery-enter-from,
    .concept-gallery-leave-to {
        height: 0%;
        opacity: 0;
    }
    .concept-gallery-enter-to,
    .concept-gallery-leave-from {
        height: 100%;
        opacity: 1;
    }
    .concept-gallery-enter-active,
    .concept-gallery-leave-active {
        transition: all 0.5s ease-in;
    }
</style>