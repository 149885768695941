<template>
    <div class='lineup-container landing-container'>
        <base-title :text='siteLanguage === "de" ? "programm" : "programme"'></base-title>
        <artist-selector :artists='artists' :active='active' @select-artist='selectArtist'></artist-selector>
        <transition name='artist-gallery'>
            <artist-gallery v-if='active !== ""' :artists='artists' :active='active' @close-gallery='selectArtist("")'></artist-gallery>
        </transition>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';

import ArtistSelector from '../UI/ArtistSelector'
import ArtistGallery from './ArtistGallery'

export default {
    components: {
        ArtistSelector,
        ArtistGallery
    },
    setup() {
        const store = useStore();
        const artists = computed(() => store.getters['landing/artists']);

        const active = ref('');

        watch(active, (newValue) => {
            if(newValue !== '') {
                setTimeout(() => {
                    document.querySelector('.lineup-container').style.gridTemplateRows = '4rem 10fr 30fr';
                }, 50);
            } else {
                setTimeout(() => {
                    document.querySelector('.lineup-container').style.gridTemplateRows = '4rem 1fr 0fr';
                }, 600);
            }
        });

        function selectArtist(artist) {
            active.value = artist
        }
        
        return {
            artists,
            active,
            selectArtist
        }
    },
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>
    .lineup-container {
        height: 100%;
        width: 100%;
        /* padding-left: 20px; */
        display: grid;
        grid-template-columns: 1fr;
        

        grid-template-rows: 4rem 1fr 0fr;
        box-sizing: border-box;
        transition: all 0.5s;
    }

    .artist-gallery-enter-from,
    .artist-gallery-leave-to {
        height: 0%;
        opacity: 0;
    }
    .artist-gallery-enter-to,
    .artist-gallery-leave-from {
        height: 100%;
        opacity: 1;
    }
    .artist-gallery-enter-active,
    .artist-gallery-leave-active {
        transition: all 0.5s ease-in;
    }
</style>