
export default {
    namespaced: true,
    state() {
        return {
            user: {
                fName: '',
                lName: '',
                email: '',
                oldEnough: false,
                password: '',
                repeatPassword: '',
                isVolunteer: false,
                buildupCommitments: {},
                teardownCommitments: {},
                careCommitments: {},
                emailConfirmed: false
            },
            continuing: false,
        };
    },
    mutations: {
        continueRegistration(state, user) {
            state.fName = user.fName;
            state.lName = user.lName;
            state.email = user.email;
            state.oldEnough = user.oldEnough;
            state.emailConfirmed = true;
            state.continuing = true;
        }
    },
    actions: {
        async submitInitialRegistration(context, registeringUser) {
            // console.log(registeringUser);
            // console.log(`${process.env.VUE_APP_SERVER_URL}/register/initial`);
            const registration = await fetch(`${process.env.VUE_APP_SERVER_URL}/register/initial`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(registeringUser)
            });
            console.log(registration);
            return registration;
        },
        async continueRegistration(context, user) {
            context.commit('continueRegistration', user);
        }
    },
    getters: {
        user(state) {
            return state.user; 
        },
        continuing(state) {
            return state.continuing;
        }
    }
}