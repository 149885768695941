<template>
    <div class='concept-gallery'>
        <transition name='concept-card'>
            <concept-card v-if='showCard' :concept='concept'></concept-card>
        </transition>
        <button class='close-button' @click='closeGallery'>✕</button>
    </div>
</template>

<script>
import ConceptCard from './ConceptCard'

export default {
    props: ['concepts', 'active'],
    emits: ['close-gallery'],
    data() {
        return {
            showCard: true
        }
    },
    components: {
        ConceptCard
    },
    computed: {
        concept() {
            return this.concepts.find(concept => concept.name === this.active);
        }
    },
    methods: {
        closeGallery() {
            console.log('asdf');
            this.$emit('close-gallery');
        }
    },
    watch: {
        active(newVal) {
            if (newVal !== '') {
                this.showCard = false;
                setTimeout(() => {this.showCard = true}, 200);
            }
        }
    }
}
</script>

<style scoped>
    .concept-gallery {
        backdrop-filter: var(--standard-backdrop);
        height: calc(100% - 30px);
        grid-row: 3 / 4;
        box-sizing: border-box;
        margin-top: 30px;
        border-radius: 40px;
        box-shadow:
            inset 0 0 10px var(--purplish);
        /* transition: all 0.5s; */
        overflow: hidden;
        background-color: var(--purplish-transparent);
        transform: scale(1);
        border: 1px dotted var(--greenish);
    }

    .close-button {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 1.5rem;
        border: none;
        background-color: transparent;
        color: var(--pinkish);
        text-shadow: 0 0 5px var(--pinkish);
        outline: none;
        transition: all 0.2s ease;
    }

    .concept-card-enter-from {
        opacity: 0;
        transform: translateX(100px);
    }
    .concept-card-enter-to {
        opacity: 1;
        transform: translateX(0);
    }

    .concept-card-leave-from {
        opacity: 1;
        transform: translateX(0);
    }
    .concept-card-enter-active {
        transition: all 0.2s ease-out;
    }
    .concept-card-leave-to {
        opacity: 0;
        transform: translateX(-100px);
    }
    .concept-card-leave-active {
        transition: all 0.2s ease-in;
    }
</style>