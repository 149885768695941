<template>
    <div class='covid-container landing-container'>
        <base-title text='covid' mode='danger'></base-title>
        <div class='covid-description'>
            <!-- <h3>WEIL MIR JA COVID HABEN</h3> -->
            <p v-if='siteLanguage === "de"'>
                <b>+++++ Das Festivalgelände darf nur mit einem gültigen Covid-Zertifikat betreten werden! +++++</b>
                <br><br>
                Um das Festival unter den aktuellen Bedingungen und Bestimmungen durchführen zu können ist der Zugang auf Personen mit einem gültigen Covid-Zertifikat beschränkt. Also kümmere dich frühzeitig darum. Das Zertifikat erhält man nach dem GGG-Prinzip (Geimpft, Genesen, Getestet) - d.h. das Zertifikat belegt, dass du entweder vollständig geimpft, genesen oder negativ getestet bist. Personen mit einem Smartphone können die COVID Certificate App des Bundes runterladen und ihr Zertifikat gleich digital mitnehmen. Alternativ kann das Zertifikat auch in Papierform zum Festival gebracht werden, wo es anschliessend überprüft wird. 
                <br><br>
                <i>Geimpft</i>
                Vollständig geimpfte bekommen seit dem 12. Juni ein Covid-Zertifikat, ausser du hast bei der Registrierung die Weitergabe zur Erstellung eines elektronischen Impfausweises abgelehnt. Die Zustimmung kannst du auch im Nachhinein im Vacme-Portal geben, indem du dich einloggst und das Häckchen neu setzt. Über das Vacme-Portal kannst du anschliessend auch das Zertifikat runterladen. 
                <br><br>
                <i>Genesen</i>
                Als Genesen gilt man ab dem 11. Tag der Ansteckung bis 180 Tage danach. Ein Covid-Zertifikat für Genesene kann über diesen Link beantragt werden und wir dir per Post nach Hause geschickt. 
                <br><br>
                <i>Getestet</i>
                Negativ getestete Personen erhalten ihr Covid-Zertifikat direkt in der COVID Certificate App.  Negative Schnelltests sind 48 und PCR-Tests 72 Stunden ab Probenentnahme gültig. Sichert euch ASAP einen Testtermin! Aber Achtung: mit einem Selbsttests erhältst du das Zertifikat nicht!
                <br><br>
                Weitere Infos findet ihr auch <a target="_blank" href="https://www.bag.admin.ch/bag/de/home/krankheiten/ausbrueche-epidemien-pandemien/aktuelle-ausbrueche-epidemien/novel-cov/covid-zertifikat.html#-965519280">auf der Website des BAG</a>.
                <br><br>
                <b>+++++ Das Festivalgelände darf nur mit einem gültigen Covid-Zertifikat betreten werden! +++++</b>


            </p>
            <p v-if='siteLanguage === "fr"'>
                <b>+++++ L'accès à la zone du festival n'est possible qu'avec un certificat Covid valide ! +++++</b>
                <br><br>
                Afin de pouvoir gérer le festival dans les conditions et règlements actuels, l'accès est limité aux personnes munies d'un certificat Covid valide. Il faut donc s'en occuper tôt. Le certificat est obtenu sur une base GGG (Vacciné, Convalescent, Testé) - c'est-à-dire que le certificat prouve que vous êtes soit entièrement vacciné, soit convalescent, soit testé négatif. Les personnes disposant d'un smartphone peuvent télécharger l'application du certificat fédéral COVID et prendre leur certificat numériquement sur-le-champ. Vous pouvez également apporter une copie papier du certificat au festival, où il sera vérifié. 
                <br><br>
                <i>Vacciné</i>
                Les personnes entièrement vaccinées recevront un certificat Covid depuis le 12 juin, sauf si vous avez refusé de le partager pour créer un certificat de vaccination électronique lors de votre inscription. Vous pouvez également donner votre consentement ultérieurement dans le portail Vacme en vous connectant et en recochant la case. Vous pouvez également télécharger le certificat via le portail Vacme. 
                <br><br>
                <i>Récupération</i>
                Vous êtes considéré comme guéri à partir du 11e jour de l'infection et jusqu'à 180 jours après. Un certificat Covid pour les personnes récupérées peut être demandé via ce lien et sera envoyé à votre domicile par courrier. 
                <br><br>
                <i>Testé</i>
                Les personnes ayant subi un test négatif recevront leur certificat Covid directement dans l'application de certificat COVID.  Les tests rapides négatifs sont valables pendant 48 heures et les tests PCR sont valables pendant 72 heures à partir du prélèvement de l'échantillon. Assurez-vous de prendre un rendez-vous pour le test dès que possible ! Mais attention : l'autodiagnostic ne vous permettra pas d'obtenir le certificat !
                <br><br>
                Vous pouvez également trouver plus d'informations sur le <a target='_blank' href="https://www.bag.admin.ch/bag/fr/home/krankheiten/ausbrueche-epidemien-pandemien/aktuelle-ausbrueche-epidemien/novel-cov/covid-zertifikat.html#-965519280"></a> site web de la BAG.
                <br><br>
                <b>+++++ L'accès à la zone du festival n'est possible qu'avec un certificat Covid valide ! +++++</b>

            </p>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>

    .covid-container {
        height: 100%;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;

        grid-template-rows: 4rem 1618fr 1000fr;
        box-sizing: border-box;
        transition: all 0.5s;
    }

    .covid-picture {
        background-image: url('https://static.mycity.travel/manage/uploads/7/36/92250/1/panorama-gibloux-atgertschen_3000.jpg');
        background-size: 300%;
        background-position: center;
        margin-top: 20px;
        border-radius: 40px;
        box-shadow: 0 0 20px var(--purplish);
    }
    .covid-description {
        color: var(--yellowish);
        overflow: scroll;
        border: 3px dashed var(--danger);
        margin-top: 20px;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 10px 15px;
        background-color: var(--danger-transparent);
        grid-row: 2/4;
        backdrop-filter: var(--standard-backdrop);
    }
    .covid-description h3 {
        margin: 0;
    }
</style>