<template>
    <div class='artist-selector'>
        <div
            v-for='artist in artists'
            class='artist-badge'
            :class='{active: active === artist.name}'
            :key='artist.name'
            @click.stop='goToArtistPage(artist.link)'
        >
        <!-- @click.stop='selectArtist(artist.name)' -->
            <h1>{{ artist.name }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    props: ['artists', 'active'],
    emits: ['select-artist'],
    methods: {
        selectArtist(artist) {
            if(this.active === artist) {
                this.$emit('select-artist', '');
                return;
            }
            this.$emit('select-artist', artist);
        },
        goToArtistPage(link) {
            console.log(link);
            if(link !== '') {
                window.open(link);
            }
        }
    },
    // watch: {
    //     active(newValue) {
    //         if(newValue === '') {
    //             document.querySelector('.artist-selector').style.gridRow = '2/4';
    //             document.querySelector('.artist-selector').style.height = 'calc(100% - 20px)';
    //         } else {
    //             document.querySelector('.artist-selector').style.gridRow = '2/3';
    //             document.querySelector('.artist-selector').style.height = 'calc(100%)';
    //         }
    //     }
    // }
}
</script>

<style scoped>
 .artist-selector {
     backdrop-filter: var(--standard-backdrop);
     margin-top: 20px;
     height: calc(100% - 20px);
     box-sizing: border-box;
     /* min-height: 10%; */
     /* max-height: 10%; */
     overflow: scroll;
     border-radius: 40px;
     box-shadow: inset 0 0 10px var(--purplish);
     grid-row: 2/3;
     transition: all 0.5s;
     border: 1px dotted var(--greenish);
 }

 .artist-badge {
     border: 1px solid var(--greenish);
     box-shadow: 0 0 10px var(--greenish);
     margin: 5px 8px;
     padding: 5px 20px;
     border-radius: 40px;
     color: var(--yellowish);
     background-color: var(--greenish-transparent);
     text-align: right;
     transition: all 0.2s ease-in-out;
 }

 .artist-badge.active {
     box-shadow: 0 0 20px var(--yellowish),
     inset 0 0 20px var(--yellowish);
     text-shadow: 0 0 10px var(--pinkish);
     backdrop-filter: invert(100%);
     transform: scale(1.02);
 }

 @media (hover: hover) and (pointer: fine) {
     .artist-badge:hover {
        box-shadow: 0 0 20px var(--pinkish),
        inset 0 0 20px var(--pinkish);
        text-shadow: 0 0 10px var(--pinkish);
        transform: scale(1.02);
    }
 }

 h1 {
     margin: 0;
 }
</style>