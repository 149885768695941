import { createStore } from 'vuex';
import landingModule from './modules/landing'
import authModule from './modules/auth'
import userModule from './modules/userData'

const store = createStore({
    modules: {
        landing: landingModule,
        auth: authModule,
        user: userModule
    }
});

export default store;