<template>
    <div class='location-container landing-container'>
        <base-title :text='siteLanguage === "de" ? "location" : "site du festival"' :size="siteLanguage === 'de' ? '2' : '1.2'"></base-title>
        <div class='location-frame'>
            <!-- <h3>LE GIBLOUX, FRIBOURG</h3> -->
                <p lang='de' v-if='siteLanguage === "de"'>
                    Du findest mich im Kanton Freiburg, an der Sprachgrenze, in der Gemeinde Gibloux, gut erschlossen. Vom Dorfzentrum von Vuisternens-en-Ogoz sind es 30 Gehminuten bis an den Waldrand. Auf der Wiese bauen wir unsere Stadt, im Wald entdecken wir Kultur. Essen und trinken sind zentral. Auf dem gesamten Gelände und dem Campingplatz gilt ein Glas-Verbot, also füll deine mitgebrachten Getränke doch im Vorhinein in andere Behälter um, danke! 

                    <br><br>
                
                    <b>Anreise</b>
                    <br>
                    Ich bitte dich, die öffentlichen Verkehrsmittel zu benutzen, da ich über keine Parkplätze verfüge. Es fahren regelmässig Busse von Fribourg bis zum Dorfzentrum von Vuisternens-en-Ogoz. Von dort spazierst du nochmals eine halbe Stunde zu diesen Koordinaten: 46.694809, 7.059165. Am besten packst du einen bequemen Rucksack. 
                    <br><br>

                    <b>Wie genau mit ÖV?</b>
                    <br>
                    Das erste Konzert findet am Freitag um 16:00 statt. Um da lokale Busnetz zu entlasten wird ein Extrabus um 14:15 vom Bahnhof Fribourg nach Vuisternens-en-Ogoz fahren. Um den zu erwischen musst du den Zug um 12:32 vom Zürich HB oder um 13:34 von Bern nach Fribourg nehmen. Ansonsten fahren reguläre Busse mehr oder weniger regelmässig bis um 23:38. 
                    <br><br>
                    Schritt für Schritt:
                    <br><br>
                    > Falls du nicht bereits in Fribourg bist, nimm doch den Zug dahin.
                    <br><br>
                    >> nimm dann entweder den Bus Nr. 336 Richtung Bulle, gare routière und steig in <i>Vuisternens-en-Ogoz, croisée</i> aus.  
                    <br><br>
                    >>> oder nimm den Bus Nr. 470 Richtung Romont FR, gare und steig in Vuisternens-en-Ogoz, le Haut aus. 
                    <br><br>
                    ---------> Such dir doch bereits im Vorhinein eine passende Verbindung raus &lt;---------
                    <br><br>
                    <b>Mit dem Velo ans Sonder?</b>
                    <br><br>
                    Genau, mit dem Velo ans Sonder. Hast du Lust und Zeit? Dann schliesse dich an und trete dem Sonder Velochat (Telegram) bei! Meine Freund*innen machen sich bereits am Mittwochabend dem 21. Juli von Zürich aus mit dem Velo auf den Weg. Mit einmal übernachten irgendwo auf halbem Weg gehts nach Bern, wo wir weitere Freunde treffen. Von da dann schliesslich zusammen nach Vuisternens-en-Ogoz zum Festivalgelände. Damit du nur mit dem Nötigsten radeln kannst, gibt es die Möglichkeit am Mittwoch vor der Losfahrt Gepäck fürs Festival abzugeben, das dann für dich zum Festivalgelände transportiert wird. Natürlich kannst du auch unabhängiger von dieser Gruppe mit dem Velo anreisen und Gepäck abgeben. Der Sonder Velochat dient dabei zur Koordination und Selbstorganisation. 
                    <br><br>
                    <a href="https://t.me/joinchat/lhXsAJGBnJxiZWU0" target='_blank'>Link zum Velochat</a> 

                </p>         
                <p lang='fr' v-if='siteLanguage === "fr"'>
                    Je vous demande d'utiliser les transports en commun car je n'ai pas de places de parking. Il y a des bus réguliers de Fribourg au centre de Vuisternens-en-Ogoz. De là, vous marchez encore une demi-heure jusqu'aux coordonnées suivantes : 46.694809, 7.059165. Il est préférable d'emporter un sac à dos confortable.
                    <br><br>                
                    <b>Arrivée</b>
                    <br>
                    Vous me trouverez dans le canton de Fribourg, à la frontière linguistique, dans la commune de Gibloux, bien connectée. Depuis le centre de Vuisternens-en-Ogoz, la lisière de la forêt se trouve à 30 minutes de marche. Dans la prairie, nous construisons notre ville, dans la forêt, nous découvrons la culture. La nourriture et les boissons occupent une place centrale. Le verre est interdit sur l'ensemble du site et du camping. Veuillez donc transvaser les boissons que vous avez apportées dans d'autres récipients à l'avance, merci!
                    <br><br>
                    <b>Comment exactement avec les transports publics ?</b>
                    <br><br>
                    Le premier concert aura lieu le vendredi à 16h00. Pour soulager le réseau de bus local, il y aura un bus supplémentaire à 14h15 de la gare de Fribourg à Vuisternens-en-Ogoz. Pour l'attraper, vous devez prendre le train à 12h32 de Zurich HB ou à 13h34 de Berne à Fribourg. Sinon, les bus réguliers circulent plus ou moins régulièrement jusqu'à 23h38. 
                    <br><br>
                    Pas à pas :
                    <br><br>
                    > Si vous n'êtes pas déjà à Fribourg, prenez-y le train.
                    <br><br>
                    >> puis prendre soit le bus n° 336 direction Bulle, gare routière et descendre à <i>Vuisternens-en-Ogoz, croisée.</i>
                    <br><br>
                    >>> ou prendre le bus n° 470 direction Romont FR, gare et descendre à Vuisternens-en-Ogoz, le Haut.
                    <br><br>
                    ---------------------> Rechercher à l'avance une connexion appropriée &lt;---------------------
                    <br><br>
                    <b>En vélo jusqu'à la Sonder ?</b>

                    Exactement, en vélo jusqu'à la Sonder. Avez-vous le temps et l'envie ? Alors rejoignez le Sonder Velochat (Télégramme) ! Mes amis partent déjà de Zurich mercredi soir, le 21 juillet, à vélo. Avec une nuitée à mi-chemin, nous nous rendrons à Berne, où nous retrouverons d'autres amis. De là, nous nous rendrons ensemble à la zone du festival à Vuisternens-en-Ogoz. Afin que vous puissiez pédaler uniquement avec les affaires les plus nécessaires, il est possible de déposer vos bagages pour le festival le mercredi avant le départ, qui seront ensuite transportés jusqu'à l'aire du festival pour vous. Bien sûr, vous pouvez aussi arriver en vélo indépendamment de ce groupe et déposer vos bagages. Le Velochat spécial sert à la coordination et à l'auto-organisation.

                    <a target='_blank' href="https://t.me/joinchat/lhXsAJGBnJxiZWU0">Link vers le Velochat</a>

                </p>         
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>
    .location-container {
        height: 100%;
        /* padding: 20px; */
        display: grid;
        grid-template-columns: 1fr;

        grid-template-rows: 4rem 1fr;
        box-sizing: border-box;
        transition: all 0.5s;
        
    }

    img {
        background-size: 100%;
        background-position: center;
        margin: 10px 0;
        border-radius: 40px;
        box-shadow:
            0 10px 5px -5px var(--greenish-transparent),
            0 0 10px var(--purplish);
        width: 100%;
    }

    .location-description {
        color: var(--yellowish);
        overflow: scroll;
        grid-row: 2/4;
        grid-column: 1;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 0 0 40px 40px;
        
    }

    .location-frame {
        background-color: var(--purplish-transparent);
        backdrop-filter: var(--standard-backdrop);
        /* border: 1px dotted var(--greenish); */
        box-shadow: 0 0 10px var(--greenish-transparent);
        height: calc(100% - 20px);
        /* grid-row: 2 / 4; */
        /* grid-column: 1; */
        margin-top: 20px;
        box-sizing: border-box;
        border-radius: 40px;
        border: 1px dotted var(--greenish);
        overflow: scroll;
        padding: var(--standard-padding);
        box-shadow: inset 0 0px 10px var(--purplish);
    }

    p {
        margin: 0;
        hyphens: auto;
        font-weight: 100;
    }
</style>