<template>
    <div class='page' title='SONDER landing page'>
        <header id='title'>
            <div class='title-box'>
                <son-glyphs id='sonder-title' text='SONDER' size='3'></son-glyphs>
                <son-glyphs v-if='siteLanguage === "de"' id='sonder-date' text='23 bis 25 juli' size='1.3'></son-glyphs>
                <son-glyphs v-if='siteLanguage === "fr"' id='sonder-date' text='23 au 25 juillet' size='1.3'></son-glyphs>
                <son-glyphs id='sonder-location' text='LE GIBLOUX' size='1.3'></son-glyphs>
            </div>
        </header>
        <language-selector />
        <section id='introduction'>
            <the-introduction />
        </section>
        <section id='lineup'>
            <the-lineup></the-lineup>
        </section>
        <!-- <section id='volunteers'>
            <the-volunteers />
        </section> -->
        <section id='location'>
            <the-location></the-location>
        </section>
        <section id='concept'>
            <the-concept></the-concept>
        </section>
        <section id='covid'>
            <the-covid></the-covid>
        </section>
        <section id='tickets'>
            <!-- <placeholder-tickets></placeholder-tickets> -->
            <the-tickets></the-tickets>
        </section>
        <footer>
            <a href='mailto:info@sonderfestival.ch'>CONTACT</a>
            <div class='spacer'></div>
            <a href='https://instagram.com/likebot9kplus'>site handcrafted by likebot9k+</a>
        </footer>
        <the-menu :items='items'></the-menu>
    </div>
</template>

<script>


import TheLineup from '../components/layout/TheLineup'
import TheLocation from '../components/layout/TheLocation'
import TheConcept from '../components/layout/TheConcept'
import TheCovid from '../components/layout/TheCovid'
// import PlaceholderTickets from '../components/layout/PlaceholderTickets'
import TheTickets from '../components/layout/TheTickets'
import TheMenu from '../components/UI/TheMenu'
import TheIntroduction from '../components/layout/TheIntroduction'
// import TheVolunteers from '../components/layout/TheVolunteers'
import LanguageSelector from '../components/UI/LanguageSelector.vue'


export default {
    components: {
        TheLineup,
        TheLocation,
        TheConcept,
        TheCovid,
        // PlaceholderTickets,
        TheTickets,
        TheMenu,
        TheIntroduction,
        // TheVolunteers,
        LanguageSelector
    },
    setup() {
        // function setHeights() {
        //     // const clientHeight = document.querySelector('html').clientHeight;
        //     // document.getElementById('title').style.height = `${clientHeight}px`;
        //     // document.getElementById('lineup').style.height = `${clientHeight - 20}px`;
        //     // document.getElementById('location').style.height = `${clientHeight - 20}px`;
        //     // document.getElementById('concept').style.height = `${clientHeight - 20}px`;
        //     // document.getElementById('covid').style.height = `${clientHeight - 20}px`;
        //     // document.getElementById('tickets').style.height = `${clientHeight - 20}px`;
        // }
        // onMounted(() => {
        //     setHeights();
        //     // console.log(document.getElementById('title').style.height);
        // });

        // window.addEventListener( 'resize', onWindowResize );

        // function onWindowResize() {
        //     setHeights();
        // }


        /////for menu/////
        const items = [
            {
                name: {
                    de: 'das festival',
                    fr: 'le festival',
                },
                href: '#introduction'
            },
            {
                name: {
                    de: 'programm',
                    fr: 'programme'
                },
                href: '#lineup'
            },
            // {
            //     name: {
            //         de: 'mitmachen',
            //         fr: 'participer'
            //     },
            //     href: '#volunteers'
            // },
            {
                name: {
                    de: 'location',
                    fr: 'site du festival'
                },
                href: '#location'
            },
            {
                name: {
                    de: 'konzept',
                    fr: 'concept'
                },
                href: '#concept'
            },
            {
                name: {
                    de: 'covid',
                    fr: 'covid'
                },
                href: '#covid'
            },
            {
                name: {
                    de: 'tickets',
                    fr: 'tickets'
                },
                href: '#tickets'
            },
        ];

        return {
            items
        }
    },
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage']
        }
    }
}
</script>

<style scoped>
    footer {
        height: 100px;
        line-height: 2;
    }
    footer a {
        background-color: var(--pinkish);
        border-radius: 20px;
        padding: 5px 20px;
        margin: 10px 10px;
        color: var(--yellowish);
        max-height: 30px;
        margin-top: 35px;
    }

    .spacer {
        flex: 1;
    }

    #title {
        height: 100vh;
    }

    .title-box {
        height: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr 3rem 1.3rem 1.3rem 1fr;
    }

    #sonder-title {
        grid-row: 2 / 3;
        margin-bottom: 10px;
    }

    #sonder-date {
        grid-row: 3 / 4;
    }

    #sonder-location {
        grid-row: 4 / 5;
    }

    section {
        /* backdrop-filter: blur(15px); */
        min-height: 100px;
        height: 100vh;

        border-radius: 40px;
        /* box-shadow: 0 0 10px var(--pinkish); */
        /* border: 1px solid var(--pinkish); */
        margin: 20px auto;
        transform: scale(1);
        max-width: 800px;
    }

    @media only screen and (min-width: 1200px) {
        .page {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 100vh 400px 600px 200px 100px;
        }

        .landing-container {
            padding: 5px;
        }

        footer {
            grid-row: 5/6;
            grid-column: 1/5;
            display: flex;
        }

        section {
        /* backdrop-filter: blur(15px); */
        min-height: 100px;
        height: 100%;

        border-radius: 40px;
        /* box-shadow: 0 0 10px var(--pinkish); */
        /* border: 1px solid var(--pinkish); */
        margin: 20px auto;
        transform: scale(1);
        max-width: 100%;
    }

        #title {
            grid-row: 1/2;
            grid-column: 1/5;
        }
        #introduction {
            grid-row: 2/3;
            grid-column: 1/4;
        }
        #lineup {
            grid-row: 2/4;
            grid-column: 4/5;
        }
        #volunteers,
        #location,
        #concept,
        #covid {
            grid-row: 3/5;
        }
        #tickets {
            grid-row: 4/5;
            grid-column: 4/5;
            width: 100%;
            height: 100%;
        }
        #concept {
            width: 100%;
        }
    }



    /* #covid { */
        /* box-shadow: 0 0 10px var(--danger); */
        /* backdrop-filter: invert(100%) var(--standard-backdrop); */
        /* border: 1px solid var(--danger); */
    /* } */

    /* #tickets div div {
        border: 1px solid var(--yellowish);
        box-shadow:
        0 0 10px var(--yellowish),
        inset 0 0 10px var(--yellowish);
        backdrop-filter: hue-rotate(120deg) blur(15px);
    } */

</style>