<template>
    <base-button class='language-selector' :class='{"hidden": !scrolling}' @click='changeSiteLanguage'>
        <span :class="{'selected' : siteLanguage === 'de'}">DE</span> | <span :class="{'selected' : siteLanguage === 'fr'}">FR</span>
    </base-button>
</template>

<script>
import { ref } from 'vue';
export default {
    setup() {
        let timeOut;
        const scrolling = ref(false);
        window.addEventListener('scroll', () => {
            scrolling.value = true;
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
                scrolling.value = false;
            }, 1400);
        });
        window.addEventListener('click', () => {
            scrolling.value = true;
            clearTimeout(timeOut);
            timeOut = setTimeout(() => {
                scrolling.value = false;
            }, 1400);
        });

        return {
            scrolling
        }
    },
    methods: {
        changeSiteLanguage() {
            if(this.siteLanguage === 'de') {
                this.$store.dispatch('landing/setLanguage', 'fr');
            } else if (this.siteLanguage === 'fr') {
                this.$store.dispatch('landing/setLanguage', 'de');
            }
        }
    },
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>
    .language-selector {
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 10;
        text-align: center;
        text-justify: center;
        transition: all 0.5s ease;
    }

    .selected {
        text-shadow: 0 0 10px var(--yellowish);
        font-size: 1.3rem;
    }


    span {
        font-size: 1rem;
    }

    .hidden {
        transform: translateY(-100px);
    }
</style>