import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js';
import store from './store/index.js';

import SonGlyphs from './components/base/SonGlyphs'
import BaseTitle from './components/base/BaseTitle'
import BaseButton from './components/base/BaseButton'
import BaseCard from './components/base/BaseCard'
import BaseSection from './components/base/BaseSection'
import BaseWarning from './components/base/BaseWarning'
import ButtonBar from './components/base/ButtonBar'
import BaseCheckbox from './components/base/BaseCheckbox'



const app = createApp(App)
.component('son-glyphs', SonGlyphs)
.component('base-title', BaseTitle)
.component('base-button', BaseButton)
.component('base-card', BaseCard)
.component('base-section', BaseSection)
.component('base-warning', BaseWarning)
.component('button-bar', ButtonBar)
.component('base-checkbox', BaseCheckbox)
.use(router)
.use(store);

app.mount('#app');