<template>
    <div class='concept-selector'>
        <div v-for='concept in concepts' class='concept-badge' :class='{active: active === concept.name}' :key='concept.name' @click='selectConcept(concept.name)'>
            <h1 v-if='siteLanguage === "de"'>{{ concept.name.de }}</h1>
            <h1 v-if='siteLanguage === "fr"'>{{ concept.name.fr }}</h1>
        </div>
    </div>
</template>

<script>
export default {
    props: ['concepts', 'active'],
    emits: ['select-concept'],
    methods: {
        selectConcept(concept) {
            if(this.active === concept) {
                this.$emit('select-concept', '');
                return;
            }
            this.$emit('select-concept', concept);
        }
    },
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>
 .concept-selector {
     backdrop-filter: var(--standard-backdrop);
     margin-top: 20px;
     height: calc(100% - 20px);
     box-sizing: border-box;
     /* min-height: 10%; */
     /* max-height: 10%; */
     overflow: scroll;
     border-radius: 40px;
     box-shadow: inset 0 0 10px var(--purplish);
     grid-row: 2/3;
     transition: all 0.5s;
     border: 1px dotted var(--greenish);
 }

 .concept-badge {
     border: 1px solid var(--greenish);
     box-shadow: 0 0 10px var(--greenish);
     margin: 5px 8px;
     padding: 5px 15px;
     border-radius: 40px;
     color: var(--yellowish);
     background-color: var(--greenish-transparent);
     transition: all 0.2s ease-in-out;
     text-align: right;
 }

 .concept-badge.active {
     box-shadow: 0 0 20px var(--yellowish),
     inset 0 0 20px var(--yellowish);
     text-shadow: 0 0 10px var(--pinkish);
     backdrop-filter: invert(100%);
     transform: scale(1.02);
 }

 @media (hover: hover) and (pointer: fine) {
     .concept-badge:hover {
        box-shadow: 0 0 20px var(--pinkish),
        inset 0 0 20px var(--pinkish);
        text-shadow: 0 0 10px var(--pinkish);
        transform: scale(1.0);
    }
 }

 h1 {
     margin: 0;
 }
</style>