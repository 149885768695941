export default {
    namespaced: true,
    state() {
        return {
            artists: [
                {
                    name: 'statement',
                    description: '',
                    link: 'https://soundcloud.com/thisisstatement'
                },
                {
                    name: 'Montis Sounds',
                    description: '',
                    link: 'https://soundcloud.com/montis_sounds/'
                },
                {
                    name: 'Olan!',
                    description: '',
                    link: 'https://olan1.bandcamp.com/'
                },
                {
                    name: 'Olivia Balena',
                    description: '',
                    link: 'https://soundcloud.com/oliviabalena'
                },
                {
                    name: 'Cosmic Space Circus',
                    description: '',
                    link: 'https://soundcloud.com/eatpowder'
                },
                {
                    name: '®iginal ©py',
                    description: '',
                    link: 'https://soundcloud.com/alicealice_yeah'
                },
                {
                    name: 'malgo',
                    description: '',
                    link: 'https://www.malgo.online'
                },
                {
                    name: 'Zohar Atkinson',
                    description: '',
                    link: 'https://www.instagram.com/yogawithzohar/'
                },
                {
                    name: 'sophie germanier',
                    description: '',
                    link: 'https://sophie-germanier.kleio.com/profile'
                },
                {
                    name: 'dustin kenel',
                    description: '',
                    link: 'https://www.dustinkenel.com'
                },
                {
                    name: 'Helena Mai',
                    description: '',
                    link: ''
                },
                {
                    name: 'kollektiv myxa',
                    description: '',
                    link: 'https://rotefabrik.ch/de/programm.html#/events/11476'
                },
                {
                    name: 'urban equipe',
                    description: '',
                    link: 'https://www.urban-equipe.ch'
                },
                {
                    name: 'Ana J. Haugwitz',
                    description: '',
                    link: ''
                },
                // {
                //     name: 'Radio Interrational',
                //     description: '',
                //     link: ''
                // },
                {
                    name: 'Best-Elle',
                    description: '',
                    link: 'https://soundcloud.com/user-41957562'
                },
                {
                    name: 'DenDala',
                    description: '',
                    link: 'https://soundcloud.com/user-495976966'
                },
                // {
                //     name: "Horse, I'm Virus",
                //     description: '',
                //     link: 'https://horseimvirus.bandcamp.com/'
                // },
                {
                    name: 'fjälla',
                    description: '',
                    link: 'https://www.instagram.com/fjaella_official/'
                },
                {
                    name: 'crème solaire',
                    description: '',
                    link: 'https://cremesolaire.ch/'
                },
                {
                    name: 'mischgewebe',
                    description: '',
                    link: 'https://mischgewebemusic.com/'
                },
                {
                    name: 'tramfahrt',
                    description: '',
                    link: 'https://instagram.com/tramfahrt/'
                },
                // {
                //     name: '$chlagerboyz',
                //     description: '',
                //     link: 'https://artist.lnk.site/boyz'
                // },
                {
                    name: 'F1-PV',
                    description: '',
                    link: 'https://soundcloud.com/f1-pv'
                },
                {
                    name: '500k',
                    description: '',
                    link: 'https://www.500k.ch/'
                },
                {
                    name: 'Chaostruppe',
                    description: '',
                    link: 'https://soundcloud.com/chaostruppe '
                },
                {
                    name: 'Tempofoifi',
                    description: '',
                    link: 'http://www.kollektivtf.ch'
                },
                {
                    name: 'Laramborghini',
                    description: '',
                    link: ''
                },
                {
                    name: 'Vinyan',
                    description: '',
                    link: 'https://www.soundcloud.com/yannic-paroxetin'
                },
                {
                    name: 'Walter Nice',
                    description: '',
                    link: 'https://soundcloud.com/user-303793773'
                },
            ],
            concepts: [
                {
                    name: {
                        de: 'Programm',
                        fr: 'Programme'
                    },
                    description: {
                        de: 'Vom Klassiker bis zum totalen Bruch des Verständnis. Ich freue mich auf ein breites Spektrum von elektronischer und akustischer Musik über Theater, Performance, Tanz, bildender Kunst bis hin zu Workshops und Diskussionen. Aber lass uns zusammen nicht bloss ein breites Spektrum an Unterhaltung konsumieren, sondern den Raum aktiv nutzen und mitgestalten. Also führe deinen Tanz auf, zeig uns deine Performance und bring deine Blockflöte. Gemeinsam erforschen wir die verschiedenen Aspekte der Spontanität. Das, was nicht spontan ist, wurde so fair und so divers wie es mir möglich war, organisiert.',
                        fr: "Du classique à la rupture totale de compréhension. J'ai hâte de découvrir un large éventail d'activités allant de la musique électronique et acoustique au théâtre, aux performances, à la danse, aux arts visuels, aux ateliers et aux discussions. Mais ensemble, ne nous contentons pas de consommer un large éventail de divertissements, mais utilisons et co-créons activement l'espace. Alors faites votre danse, montrez-nous votre performance et apportez votre flûte à bec. Ensemble, nous allons explorer les différents aspects de la spontanéité. Ce qui n'est pas spontané a été organisé de la manière la plus juste et la plus diversifiée possible.",
                    },
                    tags: {
                        de: ['Lust zur Teilhabe', 'Vielfältigkeit', 'Spontanität'],
                        fr: ['désir de participation', 'diversité', 'spontanéité']
                    }
                },
                {
                    name: {
                        de: 'Nachhaltigkeit',
                        fr: 'Écologie',
                    },
                    description: {
                        de: 'Ich stehe ein, für einen schonenden Umgang mit materiellen Ressourcen und einen respektvollen Umgang mit der Natur. Denn wir werden im Wald ein Ökosystem betreten, dessen Bewohner wir nicht sind. Nachhaltigkeit bekommt beim Festival einen zentralen Platz. Ich bitte dich, sorgfältig mit der Location umzugehen und dein eigenes Geschirr mitzubringen.',
                        fr: "Je défends une utilisation prudente des ressources matérielles et un traitement respectueux de la nature. Car dans la forêt, nous entrerons dans un écosystème dont nous ne sommes pas les habitants. Le développement durable occupera une place centrale au festival. Je vous demande de faire attention à l'emplacement et d'apporter votre propre vaisselle."
                    },
                    tags: {
                        de: ['Photosynthese', 'Ökologie', 'Kreislauf'],
                        fr: ["photosynthèse", "écologie", "cycle"]
                    }
                },
                {
                    name: {
                        de: 'Bauen',
                        fr: "Construction"
                    },
                    description: {
                        de: 'Ich lege meinen Fokus auf die Wiederverwendung von Materialien, während neu gekaufte Bauelemente der Vervollständigung dienen sollen. Jeder Ort hat gewisse, prägende Eigenschaften, einen Charakter oder eben einen “Geist”, die ihn zu dem machen, was er ist. Ich möchte sowohl die anderen Organisator*innen als auch die Besucher*innen aktiv in den kreativen Prozess mit einbeziehen, denn beim Aufbau des Festivals wollen wir gemeinsam mit Hämmer schwingen.',
                        fr: "Je me concentre sur la réutilisation des matériaux, tandis que les éléments de construction nouvellement achetés sont destinés à compléter le projet. Chaque lieu possède certaines caractéristiques, un caractère ou un \"esprit\" qui font de lui ce qu'il est. Je veux impliquer activement les autres organisateurs ainsi que les visiteurs dans le processus de création, car nous voulons manier le marteau ensemble pendant la construction du festival."
                    },
                    tags: {
                        de: ['Re-build', 'Atmosphäre', 'Zusammen'],
                        fr: ['Re-build', 'Atmosphère', 'Ensemble']
                    }
                },
                {
                    name: {
                        de: 'KochenBAR',
                        fr: "Restauration"
                    },
                    description: {
                        de: "Ich bin nicht ‘eure Köch*in’. Ich bin nur ‘unsere Küche’. Also schnall dir die Kochschürze um und trage dich beim Kauf des Tickets in eine Kochschicht ein. Für gute, abwechslungsreiche und vegetarische Küche sorgen wir gemeinsam. Es wird zu jeder Mahlzeit vegan und vegetarisch gekocht werden. Getränke werde ich nicht verschenken aber du darfst das zahlen, was du kannst oder magst. Natürlich darfst du auch deine eigenen Getränke mitnehmen (ein Hoch auf Gastro), wenn du aber Glasflaschen bringst, müssen diese draussen bleiben.",
                        fr: "Je ne suis pas \"votre cuisinier\". Je suis juste \"notre cuisine\". Enfilez donc votre tablier de cuisinier et inscrivez-vous à un poste de cuisine lorsque vous achetez votre billet. Nous fournirons ensemble une nourriture bonne, variée et végétarienne. Il y aura une cuisine végétalienne et végétarienne à chaque repas. Je ne donnerai pas de boissons mais vous pouvez payer ce que vous pouvez ou aimez. Bien sûr, vous pouvez apporter vos propres boissons (bravo à Gastro), mais si vous apportez des bouteilles en verre, elles doivent rester dehors."
                    
                    },
                    tags: {
                        de: ['4 Liter', 'Kochschicht', 'Haute Cuisine'],
                        fr: ["4 litres", "Couche de cuisson", "Haute Cuisine"]
                    }
                },
                {
                    name: {
                        de: 'Awareness',
                        fr: "Sensibilisation"
                    },
                    description: {
                        de: 'Mit meinem Awarenesskonzept wirke ich sexistischem, rassistischem, homophobem, transphobem, ableistischem oder übergriffigem Verhalten entgegen, denn solch ein Verhalten wird bei mir nicht toleriert. Ich habe Augen, Ohren und Mitgefühl und begrüsse dich gerne jederzeit im Safer Space. Zudem ist mein Awareness-Team 24 Stunden am Tag verfügbar. Es unterstützt von übergriffigem Verhalten betroffene Personen. Es setzt sich parteiisch für die betroffene Person ein und handelt nur so, wie die Person es sich wünscht.',
                        fr: "Grâce à mon concept de sensibilisation, je contre les comportements sexistes, racistes, homophobes, transphobes, ableistes ou assimilables à des agressions, parce que ce genre de comportement ne sera pas toléré à mon festival. J'ai des yeux, des oreilles et de la compassion et je serais heureux de vous accueillir au \"espace sûr\" isolé à tout moment. En plus, une équipe de sensibilisation est disponible 24 heures sur 24. Elle soutient les personnes touchées par des comportements agressifs. Elle défend les intérêts de la personne concernée et n'agit que selon ses souhaits."
                    },
                    tags: {
                        de: ['Aufmerksamkeit', 'Akzeptanz', 'Wohlbefinden'],
                        fr: ["Attention", "Acceptation", "Bien-être"]
                    }
                },
                {
                    name: {
                        de: 'Sicherheit',
                        fr: "Sécurité"
                    },
                    description: {
                        de: 'Polizei Polizei. Ich überwache dich nicht. Aus Prinzip - und auch weil ich kein Cash für Bullen und Kameras übrig habe. Um aber ein sicheres, angenehmes Umfeld zu generieren, bitte ich dich, fair zu bleiben, kein Glas mitzubringen und Aggression Zuhause zu lassen. Hoppla, falls doch etwas schief geht, stelle ich Sanität, Awareness, Safer Spaces und im Gewalt-Notfall selbst ein Sicherheitstrupp. Fühl dich sicher und pass auf Dich und deine Umwelt auf.',
                        fr: "Service de police. Je ne te regarde pas. Par principe - et aussi parce que je n'ai pas d'argent à dépenser pour des flics et des caméras. Mais pour générer un environnement sûr et agréable, je vous demande d'être fair-play, de ne pas apporter de verre et de laisser l'agressivité à la maison. Oups, si quelque chose tourne mal, je fournirai des ambulanciers, une sensibilisation, des espaces sûrs et, en cas d'urgence violente, un service de sécurité moi-même. Sentez-vous en sécurité et prenez soin de vous et de votre environnement."
                    },
                    tags: {
                        de: ['GemeinsamGegenUnterdrückung', 'ReproduzierteSicherheit', 'bettersafethansorry'],
                        fr: ["Ensemble contre l'oppression", "Sécurité reproduite", "bettersafethansorry"]
                    }
                },
            ],
            siteLanguage: 'de',
        };
    },
    mutations: {
        setLanguage(state, language) {
            state.siteLanguage = language;
        }
    },
    actions: {
        setLanguage(context, language) {
            context.commit('setLanguage', language);
        }
    },
    getters: {
        artists(state) {
            return state.artists;
        },
        concepts(state) {
            return state.concepts;
        },
        siteLanguage(state) {
            return state.siteLanguage;
        },
    }
}