<template>
    <div class='output-container' :style='outputStyle'>
        <div class='sonder-letter-container' :key='index' v-for='(image, index) in letterImages' :style='containerStyle'>
            <img class='sonder-letter' :src='letterImages[index]' :style="imgStyle">
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed, watch, toRefs } from 'vue';

export default {
    props: ['text', 'size', 'wrap'],
    setup(props) {

        const tschaeddering = false;


        // const input = ref('');
        const { text } = toRefs(props);
        const letterImages = ref([]);

        //sizing
        const imgStyle = computed(() => {
            return `width: ${props.size}rem`
        });
        const containerStyle = computed(() => {
            return `width: ${props.size}rem; max-height: ${props.size}rem`
        });

        const outputStyle = computed(() => {
            return props.wrap === 'true' ? 'flex-flow: wrap' : 'flex-flow: nowrap'
        });

        //configuration
        const times = 10;
        const duration = 50;
        const reverseTschaedder = true;
        const fileNumber = 13;
        const individualTschaedder = true;
        // const curve = 0.95;
        const revCurve = 1.08;

        // //create or load cache
        // async function createCache() {
        //     const lettersAndNumbers = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        //     const lettersAndNumbersArray = lettersAndNumbers.split('');
        //     const cache = await caches.open('songlyphs-cache');
        //     for (let i = 0; i < fileNumber; i++) {
        //         lettersAndNumbersArray.forEach((letter) => {
        //             cache.add(`/SONPHABET/INDIVIDUAL/${letter}/${letter}-${i}.png`);
        //         })
        //     }
        //     cache.add(`/SONPHABET/INDIVIDUAL/$pace/$pace.png`);
        // }

        // createCache();

        // watch(input, (newValue) => {
        //     assignAll();
        // });

        //functions
        function assignAll() {
            //SET NUMBER OF FILES HERE
            const nFiles = fileNumber;
            const output = [];
            let lastDraw = 0;
            
            //ITERATE THROUGH INPUT LETTERS
            for(const letter in text.value) {
                const letterToCompute = text.value[letter].toUpperCase();
                //ADD RANDOM PATH PER LETTER
                const regex = new RegExp("[a-zA-Z0-9]");
                // if(letterToCompute === ' ') {
                if(!regex.test(letterToCompute)) {
                    const path = `/SONPHABET/INDIVIDUAL/$pace/$pace.png`;
                    output.push(path);
                } else {
                    let randomDraw = Math.floor(Math.random() * nFiles + 1);
                    while(randomDraw === lastDraw) {
                    randomDraw = Math.floor(Math.random() * nFiles + 1);
                    }
                    lastDraw = randomDraw;
                    const path = `/SONPHABET/INDIVIDUAL/${letterToCompute}/${letterToCompute}-${randomDraw}.png`;
                    output.push(path);
                }
                //console.log(output);
                letterImages.value = output;
            }
        }

        function assignIndividual(jiggleInput) {
            const nFiles = fileNumber;
            const jiggleScale = jiggleInput;
            const paths = letterImages.value;
            let lastDraw = 0;

            paths.forEach((path, index) => {
                let randomDraw = Math.floor(Math.random() * nFiles + 1);
                //MAKE SURE RANDOM OTHER
                while(randomDraw === lastDraw) {
                    randomDraw = Math.floor(Math.random() * nFiles + 1);
                }
                lastDraw = randomDraw;

                const letter = path[22];
                const jiggle = Math.random() * jiggleScale;

                if (letter !== '$') {
                    const newPath = `/SONPHABET/INDIVIDUAL/${letter}/${letter}-${randomDraw}.png`;
                    //paths[path] = newPath;
                    setTimeout(() => {
                    paths[index] = newPath;
                    
                    }, jiggle); 
                }
            });
        }

        function shuffle(jiggle) {
            if(individualTschaedder) {
            assignIndividual(jiggle);
            } else {
            assignAll();
            }
        }

        function tschaeddara() {
            // let counter = this.times;
            // let length = this.duration;

            function tschaeddaraLoh(ctr, lth) {
                let counter = ctr;
                let length = lth;
                const curve = curve;
                if(counter > 0) {
                    setTimeout(() => {
                    //console.log(counter);
                    shuffle(length);
                    tschaeddaraLoh((counter - 1), (length ** curve));
                    }, length);
                }
            }

            function tschaeddaraLohReverse(ctr, lth) {
                let counter = ctr;
                let length = lth;
                const curve = revCurve;
                // console.log(curve)
                // console.log('counter: ' + counter);
                // console.log('length: ' + length);
                if(counter > 0) {
                    setTimeout(() => {
                    shuffle(length);
                    tschaeddaraLohReverse(counter - 1, length ** curve);
                    }, length);
                }
            }

            shuffle();

            if(!reverseTschaedder) {
                tschaeddaraLoh(times, duration);
            } else {
                tschaeddaraLohReverse(times, duration);
            }            
        }

        onMounted(() => {
            assignAll();
            if(tschaeddering) {
                tschaeddara();
            }

            //////INTERSECTION OBSERVER//////
            // let options = {
            //     // root: document.querySelector('body'),
            //     rootMargin: '0px',
            //     threshold: 1.0
            // };
            // let observer = new IntersectionObserver(() => {
            //     // tschaeddara();
            // }, options);

            // let target = document.querySelector('.output-container');

            // // console.log(document.querySelector('.output-container'));

            // observer.observe(target);
        });

        watch(text, () => {
            assignAll();
        });


        return {
            letterImages,
            imgStyle,
            containerStyle,
            outputStyle
        }
    }
}
</script>

<style scoped>
    .output-container {
        display: flex;
        flex-wrap: wrap;
        /* width: 90vw; */
        /* min-height: 5rem; */
        /* padding: 100px; */
        /* height: 500px; */
        /* margin: 0; */
        background-color: transparent;
        justify-content: center;
        flex-flow: nowrap;
        box-sizing: border-box;
        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
    }

    .sonder-letter-container {
        width: 1rem;
        max-height: 1rem;
        display: flex;
        justify-content: center;
        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
    }

    .sonder-letter {
        filter: invert() sepia(1) saturate(50) drop-shadow(0 0 20px #ffffff);
        width: 1rem;
        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
    }
</style>