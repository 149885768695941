<template>
    <div :class='{"title-container": !mode, "danger-title-container": mode === "danger"}'>
        <son-glyphs :size='size || "2"' :text='text'></son-glyphs>
    </div>
</template>

<script>
export default {
    props: ['text', 'mode', 'size']
}
</script>

<style scoped>
    .title-container {
        backdrop-filter: var(--standard-backdrop);
        /* transform:
            translateX(calc(-50% + 1rem))
            rotate(-90deg)
            translateX(-100px); */
        grid-row: 1 / 2;
        height: 4rem;
        background-color: var(--purplish-transparent);
        border: 1px dotted var(--greenish);
        
        box-sizing: border-box;
        /* max-height: 4.5rem; */
        /* width: 3rem; */
        border-radius: 4rem;
        box-shadow: inset 0 0px 10px var(--purplish);

        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

    .danger-title-container {
        backdrop-filter: var(--standard-backdrop);
        /* transform:
            translateX(calc(-50% + 1rem))
            rotate(-90deg)
            translateX(-100px); */
        grid-row: 1 / 2;
        height: 4rem;
        background-color: var(--danger-transparent);
        /* padding-top: 15px;
        padding-bottom: 0; */
        box-sizing: border-box;
        /* max-height: 4.5rem; */
        /* width: 3rem; */
        border-radius: 4rem;
        box-shadow: none;
        border: 3px dashed #f00;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
    }

</style>