<template>
    <transition name='warning'>
        <div class='warning'>
            <b><slot></slot></b>
        </div>
    </transition>
</template>

<style scoped>
    .warning {
        position: fixed;
        color: var(--danger);
        font-family: var(--main-font);
        font-size: 0.7rem;
        background-color: var(--yellowish);
        height: 2rem;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        width: 100%;
        border-radius: 20px;
        box-shadow:
            0 0 10px var(--danger);
        animation: 1s heartbeat infinite;
    }
    .warning-enter-from,
    .warning-leave-to {
        opacity: 0;
        transform: scale(0.7);
    }
    .warning-enter-to,
    .warning-leave-from {
        opacity: 1;
        transform: scale(1);
    }
    .warning-enter-active {
        transition: all 0.3s ease-out;
    }
    .warning-leave-active {
        transition: all 0.3s ease-in;
    }
</style>