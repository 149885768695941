<template>
    <div class='base-card'>
        <slot></slot>
    </div>
</template>

<style scoped>
.base-card {
        backdrop-filter: var(--standard-backdrop);
        background-color: var(--purplish-transparent);
        box-shadow: inset 0 0 10px var(--purplish);
        margin-top: 30px;
        border-radius: 40px;
        padding: 20px;
        box-sizing: border-box;
    }
</style>