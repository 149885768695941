<template>
    <div class='tickets-container'>
        <marquee-frame svgId='tickets-frame'>
        </marquee-frame>
        <div class='tickets-cta'>
            <div class='tickets-inner' :style='ticketsBackdrop'>
                <!-- <a href='https://eventfrog.ch/de/p/festivals/kunst-kultur/sonder-schoen-wenns-schoen-isch-6805501396415618507.html' class='register-button' target="_blank"> -->
                    <!-- <son-glyphs class='register-button-register' text='TICKETS' size='1.6'></son-glyphs> -->
                    <!-- <son-glyphs class='register-button-now' text='NOW' size='2.5'></son-glyphs> -->
                <!-- </a> -->
                <p lang='de' v-if='siteLanguage === "de"'>
                    +++ Hallo Mensch, ich muss dich leider enttäuschen. Die erste Ladung Tickets ist bereits ausverkauft. Aber keine Angst, wir sind optimistisch mit kommenden neue Plänen des lieben Bundesrats weitere Tickets verkaufen zu können. Schau regelmässig vorbei und sei nicht traurig. See you soon! +++
                </p>
                <p lang='fr' v-else>
                    +++Bounjour humain*e, je suis désolé de vous décevoir. Le premier lot de billets est déjà épuisé. Mais ne vous inquiétez pas, nous sommes optimistes avec les nouveaux plans du cher Conseil fédéral pour vendre plus de billets. Revenez régulièrement et ne soyez pas triste. A bientôt !+++
                </p>
                <!-- <router-link class='login-button' to='/login'>
                   <son-glyphs text='LOGIN' @click='openLogin'></son-glyphs>
                </router-link> -->
            </div>
        </div>
    </div>
</template>

<script>
import MarqueeFrame from '../base/MarqueeFrame'

export default {
    components: {
        MarqueeFrame
    },
    emits: ['open-login'],
    data() {
        return {
            hue: 0,
            speed: 1,
            interval: null
        }
    },
    methods: {
        animate() {
            this.hue += this.speed;
            if(this.hue > 360) {
                this.hue = 0;
            }
        },
        openLogin() {
            this.$emit('open-login');
        }
    },
    computed: {
        ticketsBackdrop() {
            return `backdrop-filter: var(--standard-backdrop) hue-rotate(${this.hue}deg)`;
        },
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    },
    mounted() {
        this.interval = setInterval(() => {
            this.animate();
        }, 40);
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
}
</script>

<style scoped>
    .tickets-container {
        transform: scale(1);
        height: 100%;
        width: 100%;
        border-radius: 40px;
    }
    .tickets-cta {
        background-color: transparent;
        height: 100%;
        width: 100%;
    }

    /* @media only screen and (min-width: 768px) {
        .register-button {
            height: 100;
        }
    } */

    .register-button {
        background-color: transparent;
        border: 1px solid var(--yellowish);
        grid-row: 2/3;
        outline: none;
        width: calc(100% - 40px);
        max-width: 300px;
        margin: 0 auto;
        /* height: 200px; */
        box-sizing: border-box;
        display: grid;
        grid-template-rows: 1fr 2rem 1fr;
        border-radius: 40px;
        box-shadow: 0 0 10px var(--yellowish),
        0 0 0 var(--pinkish),
        inset 0 0 10px var(--yellowish);
        transition: all 0.5s ease;
        animation: wakuwaku 3s infinite ease;
    }

    .register-button:active,
    .login-button:active {
        background: var(--yellowish);
    }

    @media (hover: hover) and (pointer: fine) {
        .register-button:hover,
        .login-button:hover {
            box-shadow:
                0 0 20px var(--yellowish),
                0 0 10px var(--pinkish),
                inset 0 0 10px var(--yellowish);
            background-color: var(--purplish);
        }
    }

    .tickets-inner {
        backdrop-filter: var(--standard-backdrop);
        position: absolute;
        border: 1px solid var(--yellowish);
        height: calc(100% - 40px);
        width: calc(100% - 40px);
        border-radius: 40px;
        box-shadow: 0 0 10px var(--yellowish),
        inset 0 0 10px var(--yellowish);
        margin: 20px;
        box-sizing: border-box;
        
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        overflow: scroll;
    }

    .register-button-register {
        grid-row: 2/3;
    }
    .register-button-now {
        grid-row: 3/4;
    }

    .login-button {
        background-color: transparent;
        border: 1px solid var(--yellowish);
        grid-row: 3/4;
        outline: none;
        /* width: calc(100% - 40px); */
        max-width: 300px;
        margin: 0 auto;
        height: 2.5rem;
        padding: 10px 20px 2px;
        box-sizing: border-box;
        border-radius: 40px;
        box-shadow: 0 0 10px var(--yellowish),
        0 0 0 var(--pinkish),
        inset 0 0 10px var(--yellowish);
        transition: all 0.5s ease;        
    }

    p {
        hyphens: auto;
        margin: 0;
        padding: 20px;
    }
</style>