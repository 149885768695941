<template>
    <div class='artist-card'>
        <div class='scrollable'>
            <h3>{{ artist.name }}</h3>
            <p>{{ artist.description }}</p>
        </div>
        <a :href="artist.link" target='_blank'><button class='more-button'>MORE</button></a>
    </div>
</template>

<script>
export default {
    props: ['artist'],
    data() {
        return {
            active: true
        }
    }
}
</script>

<style scoped>
    .artist-card {
        color: var(--yellowish);
        backdrop-filter: var(--standard-backdrop);
        border-radius: 40px;
        padding: 0 var(--standard-padding) 15px var(--standard-padding);
        transform: scale(1);
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
    }
    .artist-card h3 {
        margin: 0;
    }
    p {
        font-weight: 100;
    }

    h3 {
        margin: 0;
    }
    .scrollable {
        overflow: scroll;
        /* border: 1px solid red; */
        height: calc(100% - 50px);
        padding-top: calc(var(--standard-padding) - 5px);
        box-sizing: border-box;
        border-bottom: 1px dotted var(--purplish);
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .scrollable::-webkit-scrollbar {
        display: none;
    }


    .more-button {
        height: 2rem;
        border: none;
        border-radius: 1rem;
        padding: 0px 10px;
        font-family: var(--main-font);
        font-weight: 400;
        font-size: 1.5rem;
        outline: none;
        color: var(--yellowish);
        background-color: var(--greenish);
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    .more-button:hover {
        box-shadow: 0 0 10px var(--yellowish);
    }
    
</style>