<template>
    <div class='introduction-container landing-container'>
        <base-title size='1.6' :text="siteLanguage === 'de' ? 'das festival' : 'le festival'"></base-title>
        <div class='introduction-frame'>
            <!-- <h3>LE GIBLOUX, FRIBOURG</h3> -->
                <p lang='de' v-if='siteLanguage === "de"'>
                    Ich bin „Sonder“ - ein transdisziplinäres Kulturfestival, welches vom 23. bis zum 25. Juli 2021 in Vuisternens-en-Ogoz im Kanton Freiburg stattfindet. Ich möchte Veränderung und nehme mir drei Tage, um diesem Gefühl Ausdruck geben zu können. Abgelegen vom rationalisierten, systematischen Funktionieren kannst du das sein, was du willst und mit Menschen weiterdenken, produzieren und konsumieren. Ich will einen Austausch zwischen verschiedenen Menschen fördern und dazu lokale und interregionale Künstler*innen, Besucher*innen, Produzent*innen und Produkte mit einbeziehen. Dabei ist mir die kollektive Beteiligung wichtig: Du bist nicht ein*e passive*r Konsument*in. Du bist ein aktiver Gestaltungsprozess und sollst meinen Raum nutzen, um ihn mitzuverändern. Möglichkeiten dazu biete ich Allen in Form einer offenen Bühne, im Mitwirken an den Verpflegungs- und Getränkeständen, sowie im Bau von Kunstobjekten und Infrastruktur auf dem Gelände. Daneben ist es zu jeder Zeit möglich, spontanen Ideen nachzugehen - ob du Haare schneiden, Nägel lackieren oder Tee-Kränzchen abhalten möchtest. Du bist Teil meines Programms und ich freue mich darauf.
                    <br><br>
                    Das Programm von meiner Seite beinhaltet ein breites Spektrum an elektronischer und akustischer Musik – über Theater, Performance, Tanz, bildender Kunst bis hin zu Workshops und Diskussionen. Ich will Menschen, die sich durch subkulturelles Engagement und Experimentierfreudigkeit ausdrücken, aus unterschiedlichen Regionen zusammenbringen und eine Plattform bieten. Um im Einklang mit dem lokalen Ökosystem zu bleiben und um die Umwelt möglichst wenig zu belasten, habe ich ein durchdachtes Nachhaltigkeitskonzept. Das Einzige was ich von dir fordere, ist eine radikale Ablehnung von Diskriminierung.
                </p>         
                <p lang='fr' v-if='siteLanguage === "fr"'>
                    Je suis "Sonder" - un festival culturel transdisciplinaire qui aura lieu du 23 au 25 juillet 2021 à Vuisternens-en-Ogoz dans le canton de Fribourg. Je veux du changement et je prends trois jours pour pouvoir exprimer ce sentiment. Éloigné du fonctionnement rationalisé et systématique, vous pouvez être ce que vous voulez et continuer à penser, produire et consommer avec les gens. Je veux promouvoir un échange entre différentes personnes, impliquant des artistes, des visiteurs, des producteurs et des produits locaux et interrégionaux. La participation collective est importante pour moi : vous n'êtes pas un consommateur passif. Vous êtes un processus créatif actif et vous devriez utiliser mon espace pour contribuer à le modifier. J'offre à chacun la possibilité de le faire sous la forme d'une scène ouverte, en contribuant aux stands de nourriture et de boissons, et en construisant des objets d'art et des infrastructures sur le site. En outre, il est possible à tout moment de poursuivre des idées spontanées, qu'il s'agisse de couper les cheveux, de peindre les ongles ou d'organiser un goûter. Vous faites partie de mon programme et je m'en réjouis.
                    <br><br>
                    De mon côté, le programme comprend un large éventail de musique électronique et acoustique, ainsi que du théâtre, des performances, de la danse, des arts visuels, des ateliers et des discussions. Je veux rassembler des personnes de différentes régions qui s'expriment à travers un engagement subculturel et une volonté d'expérimentation, et leur offrir une plateforme. Pour rester en harmonie avec l'écosystème local et avoir un impact aussi faible que possible sur l'environnement, j'ai un concept de durabilité bien pensé. La seule chose que je vous demande, c'est un rejet radical de la discrimination.
                </p>         
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>
    .introduction-container {
        height: 100%;
        /* padding: 20px; */
        display: grid;
        grid-template-columns: 1fr;

        grid-template-rows: 4rem 1fr;
        box-sizing: border-box;
        transition: all 0.5s;
        
    }

    img {
        background-size: 100%;
        background-position: center;
        margin: 10px 0;
        border-radius: 40px;
        box-shadow:
            0 10px 5px -5px var(--greenish-transparent),
            0 0 10px var(--purplish);
        width: 100%;
    }

    .introduction-description {
        color: var(--yellowish);
        overflow: scroll;
        grid-row: 2/4;
        grid-column: 1;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 0 0 40px 40px;
    }

    .introduction-frame {
        background-color: var(--purplish-transparent);
        backdrop-filter: var(--standard-backdrop);
        /* border: 1px dotted var(--greenish); */
        box-shadow: 0 0 10px var(--greenish-transparent);
        height: calc(100% - 20px);
        /* grid-row: 2 / 4; */
        /* grid-column: 1; */
        margin-top: 20px;
        box-sizing: border-box;
        border-radius: 40px;
        border: 1px dotted var(--greenish);
        overflow: scroll;
        padding: var(--standard-padding);
        box-shadow: inset 0 0px 10px var(--purplish);
    }

    p {
        margin: 0;
        hyphens: auto;
        font-weight: 100;
    }
</style>