<template>
    <button class='base-button'>
        <slot></slot>
    </button>
</template>

<style scoped>
 .base-button {
        height: 2rem;
        border: none;
        border-radius: 1rem;
        padding: 0px 10px;
        font-family: var(--main-font);
        font-weight: 400;
        font-size: 1.2rem;
        outline: none;
        color: var(--yellowish);
        background-color: var(--greenish);
        box-shadow:
            0 0 10px var(--purplish),
            0 0 10px var(--purplish);
        /* position: absolute;
        bottom: 20px;
        left: 20px; */
        transition: all 0.2s ease-in-out;
    }
    .base-button:hover {
        box-shadow: 0 0 10px var(--yellowish);
    }
</style>