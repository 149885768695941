<template>
    <div class='concept-card'>
        <!-- <button class='close-button' @click='close'>✕</button> -->
        <div class='scrollable' v-if="siteLanguage === 'de'">
            <h3>{{ concept.name.de }}</h3>
            <div class='concept-tag' v-for='tag in concept.tags.de' :key='tag'>{{ tag }}</div>
            <p lang='de'>{{ concept.description.de }}</p>
        </div>
        <div class='scrollable' v-if="siteLanguage === 'fr'">
            <h3>{{ concept.name.fr }}</h3>
            <div class='concept-tag' v-for='tag in concept.tags.fr' :key='tag'>{{ tag }}</div>
            <p lang='fr'>{{ concept.description.fr }}</p>
        </div>
        <!-- <a :href="concept.link" target='_blank'><button class='more-button'>MORE</button></a> -->
    </div>
</template>


<script>
export default {
    props: ['concept'],
    // emits: ['close-card'],
    data() {
        return {
            active: true
        }
    },
    methods: {
        close() {
            this.$emit('close-card');
        }
    },
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
}
</script>

<style scoped>
    .concept-card {
        color: var(--yellowish);
        backdrop-filter: var(--standard-backdrop);
        border-radius: 40px;
        padding: 0px 20px 15px 20px;
        transform: scale(1);
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        hyphens: auto;
    }
    .concept-card h3 {
        margin: 0;
    }
    h3 {
        line-height: 2;
    }
    p {
        font-weight: 100;
    }
    .scrollable {
        overflow: scroll;
        /* border: 1px solid red; */
        height: 100%;
        padding-top: 15px;
        box-sizing: border-box;
        /* border-bottom: 1px dotted var(--purplish); */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .scrollable::-webkit-scrollbar {
        display: none;
    }

    .more-button {
        height: 2rem;
        border: none;
        border-radius: 1rem;
        padding: 0px 10px;
        font-family: var(--main-font);
        font-weight: 400;
        font-size: 1.5rem;
        outline: none;
        color: var(--yellowish);
        background-color: var(--greenish);
        position: absolute;
        bottom: 20px;
        left: 20px;
    }
    .more-button:hover {
        box-shadow: 0 0 10px var(--yellowish);
    }

    /* .close-button {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 1.5rem;
        border: none;
        background-color: transparent;
        color: var(--pinkish);
        text-shadow: 0 0 5px var(--pinkish);
        outline: none;
        transition: all 0.2s ease;
    } */

    .close-button:hover {
        text-shadow: 0 0 20px var(--pinkish),
        0 0 20px var(--pinkish),
        0 0 20px var(--pinkish);
    }

    .concept-tag {
        background-color: var(--pinkish);
        border-radius: 20px;
        padding: 5px 20px;
        margin: 5px 0
    }
    
</style>