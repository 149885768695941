<template>
     <nav id='menu'>
        <transition name='hide-background'>
            <div v-if='open' class='page-hider' @click='toggleMenu'></div>
        </transition>
        <transition name='hide-menu'>
            <button v-if='active || open' class='menu-circle' :class='{open: open}' @click='toggleMenu'>
                
                <svg viewBox='0 0 100 100' class='scroll-progress'>
                    <defs>
                        <!-- <filter id="menu-progress-glow">
                            <feDropShadow dx="0" dy="0" stdDeviation="3"
                                flood-color="var(--pinkish)" flood-opacity="1"/>
                            <feDropShadow dx="0" dy="0" stdDeviation="2"
                                flood-color="var(--purplish)" flood-opacity="1"/>
                            <feDropShadow dx="0" dy="0" stdDeviation="1"
                                flood-color="var(--greenish)" flood-opacity="1"/>
                        </filter> -->
                    </defs>
                    <path
                    height='70px'
                    width='70px'
                    :d='arcD'
                    stroke='var(--pinkish-transparent)'
                    stroke-width='3'
                    stroke-linecap='round'
                    fill='transparent'
                    >

                    </path>
                    <!-- <ellipse cx="50" cy="50" rx="45" ry="45" fill='transparent' stroke='red' pathLength='10' /> -->
                </svg>
                <son-glyphs class='menu-s' text='s' size='2'></son-glyphs>           
            </button>
        </transition>
        
        <transition name='menu-tendrils'>      
            <div id='menu-tendrils' v-if='tendrilsOpen'>
                <svg id='menu-cables' width='100%' height='100%' @click='toggleMenu'>                  
                    <path v-for='(item, index) in items' :id="index+ '-path-bg-2'" :key="item.name + '-path-bg-2'"
                        fill='none'
                        :d='bezierPaths[index]'
                        
                        stroke-width='5'
                        stroke-linecap='round'
                        stroke='var(--yellowish)'
                        />
                    <path v-for='(item, index) in items' :id="index+ '-path-bg'" :key="item.name + '-path-bg'"
                        fill='none'
                        :d='bezierPaths[index]'
                        
                        stroke-width='3'
                        stroke-linecap='round'
                        stroke='var(--pinkish)'
                        />
                    <path v-for='(item, index) in items' :id="index+ '-path'" :key="item.name + '-path'"
                        fill='none'
                        :d='bezierPaths[index]'
                        
                        stroke-width='1'
                        stroke-linecap='round'
                        stroke='var(--greenish)'
                        />
                </svg>
            </div>
        </transition>
        <transition name='menu-items'>
            <div id='menu-items' v-if='showMenuItems'>
                <button :style='buttonPositions[index]' class='menu-item' @click="scrollTo(item.href)" v-for='(item,index) in items' :key="item.name + '-link'">{{ siteLanguage === 'de' ? item.name.de : item.name.fr }}</button>
            </div>
        </transition>
    </nav>
    
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import Vivus from 'vivus';


export default {
    props: ['items'],
    setup(props) {

        //////////interaction////////
        const active = ref(false); ////
        const open = ref(false);////////////////

        let timer = ref(null);
        const displayDuration = 1300;

        function showMenu() {
            if(!active.value) {
                clearTimeout(timer.value);
                active.value = true;
                timer.value = setTimeout(
                    () => {
                        active.value = false; /////
                    }, displayDuration);
            } else {
                clearTimeout(timer.value);
                timer.value = setTimeout(
                    () => {
                        active.value = false; ////
                    }, displayDuration);
            }
        }

        document.addEventListener('scroll', showMenu);
        window.addEventListener('mousemove', showMenu);

        function toggleMenu() {
            open.value = !open.value;
            if(open.value) {
                disableScroll();
            } else {
                enableScroll();
            }
        }


        //set timeout at mount
        onMounted(() => {
            showMenu();
        });







        ///////////////////////GEOMETRY//////////////////////

        function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
            const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

            return {
                x: centerX + (radius * Math.cos(angleInRadians)),
                y: centerY + (radius * Math.sin(angleInRadians))
            };
        }

        function describeArc(x, y, radius, startAngle, endAngle){

            var start = polarToCartesian(x, y, radius, endAngle);
            var end = polarToCartesian(x, y, radius, startAngle);

            var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

            var d = [
                "M", start.x, start.y, 
                "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
            ].join(" ");

            return d;       
        }

        const arcD = ref();
        // arcD.value = describeArc(50, 50, 40, 0, 270);
        function updateArc(scrollPercentage) {
            let endAngle = scrollPercentage * 3.6;
            if(endAngle >= 360) {
                endAngle = 359.99;
            }
            arcD.value = describeArc(50, 50, 40, 0, endAngle);
        }



        /////////ITEMS GEOMETRY//////////      

        // const bezierPath = ref('');
        const bezierPaths = ref([]);
        // let angleInterval = ref(null);

        const xOffset = 50;
        const yOffset = 60;
        const gap = 10;



        //USE THESE TO CHANGE SHAPE

        //endcontrols
        const endControlYOffset = 680;
        const endControlXOffset = -120;

        const endControlYFalloff = -0.23;
        const endControlXFalloff = -0.18;
        
        //startcontrols radius
        let startPosControlOffset = 100;

        function startPosControlAnimate() {
            startPosControlOffset = Math.sin(Date.now() / 1200) * 40 + 80;
        }

        //tie point
        const tieDistanceFromCenter = 100;
        let tieAngle = ref(20);
        
        function angleAnimate() {
            tieAngle.value = Math.sin(Date.now() / 1000) * 20 + 10;
        }
        
        const tieWidth = 30;
        //angle between tie and tie-center line
        const offsetTieAngle = tieAngle.value + 60;

        //tie-start controls
        const tieStartControlWidth = 30; //fanning
        const tiePointsStartControlOffset = 50; //distance of control points

        //tie-end controls
        const tieEndControlWidth = 30; //fanning
        const tiePointsEndControlOffset = -100; //control points

        ////////open animation///////
        
        let tendrilAnimation;

        function extendTendrils() {
            tendrilAnimation = new Vivus('menu-cables', {duration: 70, start: 'manual', animTimingFunction: Vivus.EASE});
            tendrilAnimation.play(1);
            setTimeout(() => {
                showMenuItems.value = true;
            },500);
        }

        function retractTendrils() {
            showMenuItems.value = false;
            tendrilAnimation.play(-1.5);
        }

        window.addEventListener('resize', () => {
            if(tendrilsOpen.value === true) {
                // clearInterval(angleInterval.value);

                initializeDrawVariables();
                setAllItemPoints();
                setBezierCoordinates();

                // animateTendrils();
            }
        });


        function animateTendrils() {
                        startPosControlAnimate();
                        angleAnimate();
                        setTiePoints();
                        setTieStartControlPoints();
                        setStartControlPoints();
                        setTieEndControlPoints();
                        setBezierCoordinates();
                        if(tendrilsOpen.value) {
                            window.requestAnimationFrame(animateTendrils);
                        }
        }

        watch(open, (newValue) => {
            if(newValue === true) {
                tendrilsOpen.value = true;
                setTimeout(() => {
                    initializeDrawVariables();
                    setAllItemPoints();
                    setBezierCoordinates();
                }, 10);
                setTimeout(() => {
                    extendTendrils();
                    window.requestAnimationFrame(animateTendrils);
                },11);
            }
            if(newValue === false) {
                retractTendrils();
                tendrilsOpen.value = false;
                // clearInterval(angleInterval.value);
            }
        });

        const tendrilsOpen = ref(false);

        // watch(open, (newValue, oldValue) => {
        //     if(newValue === true && oldValue === false) {
        //         tendrilsOpen.value = true;
        //         extendTendrils();
        //     }
        //     if(newValue === false && oldValue === true) {
        //         setTimeout(() => {
        //             tendrilsOpen.value = false;
        //         },520);
        //         retractTendrils();
        //     }

        // });
        


        let boundingRect,
        height,
        usableHeight,
        width,
        menuX,
        menuY;
        let itemsEndPointsX = ref([]);
        let itemsEndPointsY = ref([]);
        let itemsEndControlPointsX = [];
        let itemsEndControlPointsY = [];
        let itemsStartPointsX = [];
        let itemsStartPointsY = [];
        let itemsStartControlPointsX = [];
        let itemsStartControlPointsY = [];

        let tiePoints = {
            x: [],
            y: []
        };
        let tiePointsStartControl = {
            x: [],
            y: []
        };
        let tiePointsEndControl = {
            x: [],
            y: []
        };
        

        function initializeDrawVariables() {
            boundingRect = document.getElementById('menu-tendrils').getBoundingClientRect();
            height = boundingRect.height;
            usableHeight = height / 1.618;
            width = boundingRect.width;
            menuX = width/2;
            menuY = height-70;
        }

        function setEndPoints() {
            for(const item in props.items) {
                const xPos = xOffset + item * gap;
                const yPos = yOffset + item * (usableHeight/props.items.length);
                itemsEndPointsX.value.push(xPos);
                itemsEndPointsY.value.push(yPos);
            }
        }

        function setEndControlPoints() {
            for(const item in props.items) {
                const xPos = xOffset + endControlXOffset * (1 + item * endControlXFalloff) + item * gap;
                const yPos = yOffset + endControlYOffset * (1 + item * endControlYFalloff) + item * (usableHeight/props.items.length);
                itemsEndControlPointsX.push(xPos);
                itemsEndControlPointsY.push(yPos);
            }
        }

        function setStartPoints() {
            const documentHeight = scrollHeight();

            itemsStartPointsX.splice(0, props.items.length);
            itemsStartPointsY.splice(0, props.items.length);

            console.log(props.items[0].href);
            console.log(document.querySelector(props.items[0].href));
            for(const item in props.items) {
                const itemPosition = document.querySelector(props.items[item].href).getBoundingClientRect().top + window.scrollY;
                
                //get percentage of total height for element position
                const itemPercentage = itemPosition / (documentHeight / 100);
                let itemAngle = itemPercentage * 3.6 + 180;
                if (itemAngle > 360) {
                    itemAngle -= 360
                }
                if(itemAngle < 0) {
                    itemAngle += 360
                }
                
                const position = polarToCartesian(width/2,height-45,27.5,itemAngle);
                                
                itemsStartPointsX.push(position.x);
                itemsStartPointsY.push(position.y);
            }
        }

        function setStartControlPoints() {
            itemsStartControlPointsX.splice(0, props.items.length);
            itemsStartControlPointsY.splice(0, props.items.length);
            for(const item in props.items) {
                
                const itemPosition = document.querySelector(props.items[item].href).getBoundingClientRect().top + window.scrollY;
                
                //get percentage of total height for element position
                const itemPercentage = itemPosition / (scrollHeight() / 100);
                let itemAngle = itemPercentage * 3.6 + 180;
                if (itemAngle > 360) {
                    itemAngle -= 360
                }
                if(itemAngle < 0) {
                    itemAngle += 360
                }
                
                const position = polarToCartesian(width/2,height-45,27.5 + startPosControlOffset,itemAngle);
                
                const xPos = position.x;
                const yPos = position.y;
                
                itemsStartControlPointsX.push(xPos);
                itemsStartControlPointsY.push(yPos);
            }
        }

        function setTiePoints() {
            //set tie center

            const tie = polarToCartesian(menuX,menuY,tieDistanceFromCenter,tieAngle.value);

            //all tie points
            tiePoints.x.splice(0, props.items.length);
            tiePoints.y.splice(0, props.items.length);

            for (const item in props.items) {
                const offsetDistance = (item * (tieWidth/(props.items.length-1))) - tieWidth/2;
                const newPositions = linearOffset(tie.x,tie.y,offsetDistance,offsetTieAngle);
                tiePoints.x.push(newPositions.x);
                tiePoints.y.push(newPositions.y);
            }
        }

        function setTieStartControlPoints() {
            //new tie center
            const tieStartControlOffsetCenter = polarToCartesian(menuX,menuY,tieDistanceFromCenter-tiePointsStartControlOffset,tieAngle.value);

            tiePointsStartControl.x.splice(0, props.items.length); //clear arrays
            tiePointsStartControl.y.splice(0, props.items.length);
            for (const item in props.items) {
                const offsetDistance = (item * (tieStartControlWidth/(props.items.length-1))) - tieStartControlWidth/2;
                const newPositions = linearOffset(tieStartControlOffsetCenter.x,tieStartControlOffsetCenter.y,offsetDistance,offsetTieAngle);
                tiePointsStartControl.x.push(newPositions.x);
                tiePointsStartControl.y.push(newPositions.y);
            }
        }

        function setTieEndControlPoints() {
            //new tie center
            const tieEndControlOffsetCenter = polarToCartesian(menuX,menuY,tieDistanceFromCenter-tiePointsEndControlOffset,tieAngle.value);

            tiePointsEndControl.x.splice(0, props.items.length); //clear arrays
            tiePointsEndControl.y.splice(0, props.items.length);
            for (const item in props.items) {
                const offsetDistance = (item * (tieEndControlWidth/(props.items.length-1))) - tieEndControlWidth/2;
                const newPositions = linearOffset(tieEndControlOffsetCenter.x,tieEndControlOffsetCenter.y,offsetDistance,offsetTieAngle);
                tiePointsEndControl.x.push(newPositions.x);
                tiePointsEndControl.y.push(newPositions.y);
            }
        }

        //calculate tie points
            function linearOffset(x, y, distance, angleInDegrees) {
                const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
                const newX = x + Math.cos(angleInRadians) * distance;
                const newY = y + Math.sin(angleInRadians) * distance;
                return {
                    x: newX,
                    y: newY
                };
            }



        function setAllItemPoints() {
            
            //set endPoints

            setEndPoints();

            //set end control points

            setEndControlPoints();

            //set startPoints

            setStartPoints();

            //set startControlPoints

            setStartControlPoints();

            //set tie

            setTiePoints();

            //set tie start control points

            setTieStartControlPoints();

            //tie point end control points

            setTieEndControlPoints();

        }

        function setBezierCoordinates() {
            bezierPaths.value.splice(0, props.items.length);
            for (const item in props.items) {
                // console.log(tiePointsEndControl.y[item]);
                const d = `
                    M ${itemsStartPointsX[item]}, ${itemsStartPointsY[item]}
                    C ${itemsStartControlPointsX[item]}, ${itemsStartControlPointsY[item]}, ${tiePointsStartControl.x[item]}, ${tiePointsStartControl.y[item]}, ${tiePoints.x[item]}, ${tiePoints.y[item]}
                    C ${tiePointsEndControl.x[item]}, ${tiePointsEndControl.y[item]}, ${itemsEndControlPointsX[item]}, ${itemsEndControlPointsY[item]}, ${itemsEndPointsX.value[item]}, ${itemsEndPointsY.value[item]}
                    l 2, -3
                    l 2, 3
                    l 2, -3
                    l 2, 3
                    l 2, -3
                    l 2, 3
                    l 2, -3
                    l 2, 3
                `
                bezierPaths.value.push(d);
            }
        }


        //////scrolling//////

        // const scrollPercentage = computed(() => {
        //     return window.scrollY;
        // });

        const currentScrollHeight = ref(null);

        function scrollHeight() {
            return Math.max(
                document.body.scrollHeight, document.documentElement.scrollHeight,
                document.body.offsetHeight, document.documentElement.offsetHeight,
                document.body.clientHeight, document.documentElement.clientHeight
            );
        }

        function scrollPercentage(scrollHeight, scrollY) {
            const scrollableHeight = scrollHeight - window.innerHeight;
            const onePercent = scrollableHeight / 100;
            let percentage = scrollY / onePercent;
            if(percentage > 100) {
                percentage = 100;
            }
            return percentage
        }

        // const scrollPercentage = computed(() => {
        //     let onePercent = scrollHeight.value / 100;
        //     let percentage = window.scrollY / onePercent;
        //     return percentage
        // });

        onMounted(() => {
            currentScrollHeight.value = scrollHeight();
        });

        window.addEventListener('resize', () => {
            currentScrollHeight.value = scrollHeight();
        });

        window.addEventListener('scroll', () => {
            updateArc(scrollPercentage(currentScrollHeight.value, window.scrollY));
        });

        //scroll prevention

        function disableScroll() {
            document.body.classList.add("stop-scrolling");
        }
  
        function enableScroll() {
            document.body.classList.remove("stop-scrolling");
        }


        /////////////BUTTON STUFF///////////

        function scrollTo(href) {
            if(open.value) {
                toggleMenu();
            }
            document.querySelector(href).scrollIntoView({behavior: 'smooth'});
        }

        const buttonPositions = computed(() => {
            const positionsArray = [];
            for(const item in props.items) {
                positionsArray.push(`
                    left: ${itemsEndPointsX.value[item]}px;
                    top: ${itemsEndPointsY.value[item]}px;
                `);
            }
            
            return positionsArray
        });

        const showMenuItems = ref(false);
        

        return {
            active,
            open,
            arcD,
            toggleMenu,
            // endPos,
            // endPosControl,
            // startPos,
            // startPosControl,
            // tieCenter,
            // tiePoints,
            // tiePointsStartControl,
            // tiePointsEndControl,
            bezierPaths,
            tendrilsOpen,
            scrollTo,
            buttonPositions,
            showMenuItems
        }
    },
    computed: {
        siteLanguage() {
            return this.$store.getters['landing/siteLanguage'];
        }
    }
    
}
</script>

<style scoped>
    #menu {
        position: fixed;
        top: 0;
        left: 0;
    }

    .menu-circle {
        width: 70px;
        height: 70px;
        background-color: var(--purplish-transparent);
        /* backdrop-filter: blur(5px); */
        border: 1px solid var(--yellowish);
        box-shadow: 0 0 10px var(--yellowish),
        inset 0 0 10px var(--yellowish);
        position: fixed;
        bottom: 10px;
        left: calc(50% - 35px);
        border-radius: 35px;
        z-index: 1000;
        transform: scale(1);
        padding: 0;
        outline: none;
        transition: all 0.5s ease;
    }

    .hide-menu-enter-from,
    .hide-menu-leave-to {
        opacity: 0;
        /* filter: blur(10px); */
        transform: translateY(100px) scale(0.8);
    }
    .hide-menu-enter-to,
    .hide-menu-leave-from {
        opacity: 1;
        /* filter: blur(0px); */
        transform: translateY(0) scale(1);
    }
    .hide-menu-enter-active {
        transition: all 0.3s ease-out;
    }
    
    .hide-menu-leave-active {
        transition: all 0.3s ease-in;
    }

    .scroll-progress {
        transform: rotate(180deg);
    }

    .menu-s {
        position: absolute;
        top: 20px;
        left: 18px;
    }

    .open {
        box-shadow: 0 0 20px var(--yellowish),
        inset 0 0 20px var(--yellowish);
    }

    .page-hider {
        position: fixed;
        top: 0;
        left: 0;
        height: 120vh;
        width: 120vw;
        background-color: var(--purplish-transparent);
        backdrop-filter: blur(10px);
    }

    .hide-background-enter-from,
    .hide-background-leave-to {
        opacity: 0;
    }
    .hide-background-enter-to,
    .hide-background-leave-from {
        opacity: 1;
    }
    .hide-background-enter-active {
        transition: all 0.3s ease-out;
    }
    .hide-background-leave-active {
        transition: all 0.3s ease-in;
    }
    #menu-tendrils {
        position: fixed;
        height: 100%;
        width: 100%;
        /* max-width: 500px; */
        top: 0;
        left: 0;
        z-index: 999;
    }

    .menu-tendrils-enter-from {
        opacity: 0;
    }
    .menu-tendrils-enter-to {
        opacity: 1;
    }
    .menu-tendrils-enter-active {
        transition: all 0.5s ease-out;
    }
    .menu-tendrils-leave-from {
        opacity: 1;
    }
    .menu-tendrils-leave-to {
        opacity: 0;
    }
    .menu-tendrils-leave-active {
        transition: all 0.7s ease-in;
    }

    .menu-item {
        position: fixed;
        z-index: 1000;
        outline: none;
        font-family: var(--main-font);
        background-color: var(--greenish-transparent);
        border: 1px solid var(--greenish-transparent);
        color: var(--yellowish);
        font-size: 1.4rem;
        padding: 5px 30px;
        box-sizing: border-box;
        border-radius: 40px;
        transform: translateY(-30px) translateX(10px);
        box-shadow: 0 0 10px var(--yellowish);
    }

    .menu-items-enter-from,
    .menu-items-leave-to {
        opacity: 0;
    }
    .menu-items-enter-to,
    .menu-items-leave-from {
        opacity: 1;
    }
    .menu-items-enter-active {
        transition: all 0.2s ease-out;
    }
    .menu-items-leave-active {
        transition: all 0.2s ease-in;
    }

    @media (hover: hover) and (pointer: fine) {
     .menu-item:hover {
        box-shadow: 0 0 20px var(--pinkish),
        inset 0 0 20px var(--pinkish);
        text-shadow: 0 0 10px var(--pinkish);
        transform: translateY(-35px) translateX(10px) scale(1.1);
        transition: all 0.5s ease;
    }
 }

</style>

<style>
.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
</style>