<template>
  <the-background></the-background>
  <!-- <div class='footer-shower'></div> -->
  <router-view v-slot="{ Component }">
    <transition name='router-transition' mode='out-in'>
      <component :is="Component" />
    </transition>
  </router-view>
  <!-- <footer>
    <div class='footer-clipper'></div>
  </footer> -->
</template>

<script>
import TheBackground from './components/layout/TheBackground'

export default {
  components: {
    TheBackground,
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');

:root {
  --greenish: #038CA6;
  --purplish: #58499d;
  --yellowish: #ffe946;
  --pinkish: #C149B9;
  --danger: #ff0000;
  --greenish-transparent: #038ba63d;
  --purplish-transparent: #58499d65;
  --yellowish-transparent: #EAE2A280;
  --pinkish-transparent: #C149B980;
  --danger-transparent: #ff000036;

  --standard-backdrop: blur(15px);

  --standard-padding: 30px;

  --main-font: Roboto Mono, monospace;
}

body {
  margin: 0;
  background-color: black;
}

h1, h2, h3, h4, h5, h6, p, html {
  font-family: var(--main-font);
  color: var(--yellowish);
}

div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

div::-webkit-scrollbar {
  display: none;
}

.base-container {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.5s;
}

.page {
  padding: 0 10px;
}

/* .footer-shower {
  height: 100;
  background-color: blue;
} */

@keyframes heartbeat {
  0% {
    transform: scale(0.99);
  }
  40% {
    transform: scale(1)
  }
  48% {
    transform: scale(1.01);
  }
  55% {
    transform: scale(1);
  }
  63% {
    transform: scale(1.01);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.99);
  }
}

@keyframes wakuwaku {
  0% {
    transform: rotateZ(0);
  }
  48% {
    transform: rotateZ(0);
  }
  55% {
    transform: rotateZ(10deg);
  }
  63% {
    transform: rotateZ(-10deg);
  }
  70% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(0);
  }
}
</style>

<style scoped>
  .router-transition-enter-from {
    transform: translateX(50px) scale(1);
    opacity: 0;
  }
  .router-transition-enter-to,
  .router-transition-leave-from {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  .router-transition-enter-active {
    transition: all 0.5s ease-out;
  }

  .router-transition-leave-to {
    transform: translateX(-50px) scale(1);
    opacity: 0;
  }
  .router-transition-leave-active {
    transition: all 0.5s ease-in;
  }

</style>