import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from './pages/LandingPage';
// import RegistrationPage from './pages/RegistrationPage';
// import DummyPage from './pages/DummyPage';
// import LoginPage from './pages/LoginPage'
// import AdminPage from './pages/AdminPage'
// import UserPage from './pages/UserPage'
// import ConfirmPage from './pages/ConfirmPage'
// import EntryPage from './pages/EntryPage'
// import store from './store/index'

// import CoachDetail from './pages/coaches/CoachDetail.vue';
// import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: LandingPage },
        // { path: '/register', component: RegistrationPage },
        // {
        //     path: '/register/confirm', component: RegistrationPage,
        //     beforeEnter: async (to, from, next) => {
        //         const confirmingUser = await fetch(`${process.env.VUE_APP_SERVER_URL}/register/confirm`, {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify({
        //                 key: to.query.key
        //             })
        //         });
        //         const confirmingUserData = confirmingUser.json();
        //         console.log(confirmingUserData);
        //         store.dispatch('user/continueRegistration', await confirmingUserData)
        //         .then(next());
        //     }
        // },
        // { path: '/dummy', component: DummyPage },
        // { path: '/login', component: LoginPage },
        // {
        //     path: '/admin',
        //     component: AdminPage,
        //     children: [
        //         {
        //             path: 'confirm',
        //             component: ConfirmPage
        //         },
        //         {
        //             path: 'entry',
        //             component: EntryPage
        //         },
        //     ]
        // },
        // { path: '/user', component: UserPage },
    ]
});

// router.beforeEach((to, _, next) => {
//     if(to.meta.requiresAuth && !store.getters.isAuthenticated) {
//         next('/auth');
//         return;
//     } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
//         next('/coaches');
//         return;
//     }
//     next();
// });

export default router;